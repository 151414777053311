import axios from "axios";
import {message} from "@/utils/message";
import router from "@/router";

axios.defaults.withCredentials = true;
export const $ajax = axios.create({
    headers: {
        'Content-Type': 'application/json,charset=UTF-8'
    },
    withCredentials: true
})

export const apiUrl = process.env.VUE_APP_API_URL
export const uploadImageUrl = process.env.VUE_APP_IMAGE_URL || "/jz_image/"
$ajax.interceptors.request.use((config) => {
    let token = window.localStorage.getItem("merchant_account");
    if (token) {
        config.headers['Authorization'] = token;
    }
    return config
}, (error) => {
    // 对请求错误做些什么
    return Promise.reject(error)
})

$ajax.interceptors.response.use((response) => response, (error) => {
    let _message = {
        message: "系统错误，请稍候再试",
        duration: 1000
    };
    if (error.response && error.response.data) {
        _message.message = error.response.data.errMsg || "系统错误，请稍候再试"
        if (error.response.data.errCode === 7004) {
            _message.onClose = () => {
                router.replace({name: "login"});
            }
        }
    }
    message.error(_message)

    return Promise.reject(error)
})
