import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/LoginView.vue')
    },
    {
        path: '/success',
        name: 'success',
        component: () => import('@/views/SuccessView.vue')
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('@/views/UserView.vue')
    },
    {
        path: '/scoreLst',
        name: 'scoreLst',
        component: () => import('@/views/ScoreLstView.vue')
    },
    {
        path: '/order',
        name: 'order',
        component: () => import('@/views/OrderLstView.vue')
    },
    {
        path: '/change',
        name: 'change',
        component: () => import('@/views/ChangeScoreView.vue')
    },
    {
        path: '/transfer',
        name: 'transfer',
        component: () => import('@/views/TransferView.vue')
    },
    {
        path: '/setting',
        name: 'setting',
        component: () => import('@/views/SettingView.vue')
    },
    {
        path: '/car',
        name: 'car',
        component: () => import('@/views/CarView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = "车主服务中心"
    next()
    // if (to.name !== 'login') {
    //     //验证登录信息
    //     $ajax.post(`${apiUrl}authorization/checkMerchantLogin`).then(({data:{result}}) => {
    //         window.localStorage.setItem("merchant_account_login", JSON.stringify(result))
    //         next()
    //     }).catch(() => {
    //         next({name: 'login'})
    //     })
    // } else {
    //     next()
    // }
})


export default router
