import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import "@/assets/fonts/default-icon/iconfont.css"
// import "@/assets/fonts/money-icon/money.css"
// import "@/assets/fonts/menu-icon/menu-icon.css"
// import "@/assets/fonts/statistics/statistics.css"
import "@/assets/common.less"

const app = createApp(App)
for (let elementPlusIconsVueKey in ElementPlusIconsVue) {
    app.component(elementPlusIconsVueKey, ElementPlusIconsVue[elementPlusIconsVueKey])
}

// 在main.ts里面配置一下代码,不然按钮不会显示
app.isCustomElement = (tag) => tag.startsWith('wx-open-launch-weapp')

app.use(store).use(router).use(ElementPlus, {
    locale: zhCn
}).mount('#app')

