import {createStore} from 'vuex'
import dayjs from "dayjs";
import {$ajax, apiUrl} from "@/utils/ajax";

export default createStore({
    state: {
        account: {}
    },
    getters: {},
    mutations: {
        logout() {
            window.localStorage.removeItem("html_account")
            this.state.account = {}
        }
    },
    actions: {
        getUser() {
            let account = window.localStorage.getItem("html_account")
            if (!account) return
            account = JSON.parse(account)
            let number = dayjs(account.last_time * 1000).diff()
            if (number <= 0) return;
            //获取账号信息
            $ajax.get(`${apiUrl}api/membership/query`, {params: {id: account.id}}).then(({data: {result}}) => {
                this.state.account = result
            })
        }
    },
    modules: {}
})
