<script setup>
import FooterComponent from "@/components/footer/FooterComponent.vue";
import {computed, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {$ajax, apiUrl} from "@/utils/ajax";
import store from "@/store";
import router from "@/router";
import {ElMessage} from "element-plus";
import CustomerComponent from "@/components/empty/CustomerComponent.vue";

const active_toolbar = ref(0)
const giftImg = require("@/assets/img/gif.png")
const changeMoney = ref(null)
const route = useRoute()
const product = ref()
const price = computed((() => {
  if (!/^\d+$/.test(changeMoney.value)) return 0
  return parseFloat(product.value?.attr[changeMoney.value]?.price)
}))
const stock = computed(() => {
  if (!/^\d+$/.test(changeMoney.value)) return 0
  return (product.value?.attr[changeMoney.value]?.stock)
})
const article = ref(false)
const loading = ref(false)
const showArticle = ref(false)
const account = computed(() => store.state.account)
onMounted(() => {
  store.dispatch("getUser")
  let brand = route.query.brand || "中国石油"
  $ajax.get(`${apiUrl}api/product/brand`, {params: {brand}}).then(({data: {result}}) => {
    product.value = result
  })
})
const payment = () => {
  if (Object.keys(account.value).length < 1) {
    return router.push({name: 'login'})
  }
  if (product.value.article && !article.value) {
    return ElMessage.error('请先阅读并同意服务协议')
  }
  if (price.value <= 0) {
    return ElMessage.error('请选择兑换金额')
  }
  if (price.value > account.value.balance) return ElMessage.error("账户可用积分不足")
  let flag = false
  product.value.account.forEach(account => {
    // console.log(account)
    let rule = null
    if (account.rule) rule = new RegExp(account.rule);
    if ((account.required && !account.value) || (rule && !rule.test(account.value))) {
      flag = true
      ElMessage.error(`请输入${account.name}`)
    }
  })
  if (flag) return
  loading.value = true
  $ajax.post(`${apiUrl}api/order/create`, {
    product_id: product.value.id,
    attr_id: product.value.attr[changeMoney.value].id,
    uid: account.value.id,
    account: JSON.stringify(product.value.account)
  }).then(() => {
    loading.value = false
    router.push({name: 'success'})
  }).catch(() => loading.value = false)
}
</script>
<template>
  <el-scrollbar class="page-content">
    <el-card class="banner">
      <el-image :src="product?.pic"/>
    </el-card>
    <div class="header">
      <div class="banner">
        <div style="display: flex;align-items: center;justify-content: space-between">
          <div class="balance" v-if="Object.keys(account).length">当前可用积分： {{ account?.balance || 0 }}</div>
          <div class="balance" @click="router.push({name:'login'})" v-else>登陆后查看可用积分</div>
          <div class="banner-img">
            <el-image :src="giftImg"/>
          </div>
        </div>
      </div>
      <div class="header-content">
        <el-card class="change-data">
          <div class="header-tips">
            {{ product?.brand }}
          </div>
          <div class="header-content-items">
            <el-input :placeholder="`请输入${act.name}`" v-for="act in product?.account" :key="act.text"
                      v-model="act.value" clearable size="large"/>
            <el-row :gutter="10">
              <el-col :span="8" v-for="(mon,inx) in product?.attr" :key="inx"
                      @click="changeMoney = mon.stock > 0?inx:null">
                <el-card class="money-card" shadow="hover"
                         :class="{'money-active': changeMoney === inx,'money-disable':mon.stock < 1}">
                  <div style="position: relative">
                    <div class="money-prices">
                      <div class="unit">￥</div>
                      <div class="money">{{ parseFloat(mon.face_value) }}</div>
                    </div>
                    <div class="tips" v-if="mon.stock < 1">
                      <el-text type="warning">补货中，请稍候</el-text>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
            <div class="article" v-if="product?.article" style="text-align: center">
              <el-checkbox v-model="article">
                我已阅读并同意
                <el-text type="primary" style="padding-left: 5px" @click="article=false,showArticle=true">
                  《积分兑换服务协议》
                </el-text>
              </el-checkbox>
            </div>
          </div>
        </el-card>
        <el-button type="primary" round size="large" color="#F28F00" style="color: #ffffff" icon="Coin"
                   :disabled="price<=0 || Object.keys(account).length < 1 || price > account.balance || stock < 1"
                   @click="payment"
                   :loading="loading">
          <span style="padding-right: 15px">{{ price }}</span> 立即兑换
        </el-button>
      </div>
    </div>
    <div class="content">
      <div class="content-toolbar">
        <div class="toolbar-its" :class="{'active-toolbar': active_toolbar === 0}" @click="active_toolbar=0">兑换须知
        </div>
        <div class="toolbar-its" :class="{'active-toolbar': active_toolbar === 1}" @click="active_toolbar=1">使用说明
        </div>
      </div>
      <div class="recharge-its" v-if="active_toolbar===0" v-html="product?.content"></div>
      <div class="step" v-if="active_toolbar === 1" v-html="product?.detail"></div>
    </div>
  </el-scrollbar>
  <footer-component active="home"/>
  <el-drawer direction="btt" v-model="showArticle" title="积分兑换服务协议" :show-close="false"
             style="border-radius: 18px 18px 0 0" size="75%" @closed="article=true">
    <div class="article-its" v-html="product?.article"></div>
  </el-drawer>
  <customer-component/>
</template>

<style scoped lang="less">
.header {
  margin-bottom: 15px;

  .banner {
    box-sizing: border-box;
    padding: 15px;
    //渐变背景色
    background: linear-gradient(90deg, #F28F00 0%, #FCC900 100%);
    //background: var(--el-color-primary);
    color: #fff;
    height: 120px;
    //display: flex;
    //align-items: center;
    //justify-content: space-between;
    .banner-img {
      width: 35px;
    }
  }

  .header-content {
    padding: 15px;
    box-sizing: border-box;
    background: #ffffff;

    .change-data {
      margin-top: -80px;
      --el-card-border-radius: 14px;
      --el-card-padding: 0;
      margin-bottom: 20px;

      .header-tips {
        margin: 15px 0;
        padding-left: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        background: rgba(199, 0, 10, 0.15);
        width: 85px;
        border-radius: 0 45px 45px 0;
        color: #C7000A;
        font-weight: 600;
      }

      .header-content-items {
        margin: 15px 15px 0;

        .el-input {
          --el-input-border-radius: 45px;
          margin-bottom: 15px;
        }

        .money-card {
          --el-card-border-radius: 8px;
          --el-card-body-padding: 15px;
          background: var(--el-color-primary-light-8);
          color: var(--el-color-primary);
          margin-bottom: 10px;

          &.money-active {
            background: linear-gradient(90deg, #F28F00 0%, #FCC900 100%);
            color: #ffffff;
          }

          &.money-disable {
            background: var(--el-color-info-light-8);
            color: var(--el-color-info);
          }

          .tips {
            position: absolute;
            left: -15px;
            right: -15px;
            text-align: center;
            bottom: -15px;

            .el-text {
              font-size: .8rem
            }

            height: auto;
            line-height: 25px;
          }

          .money-prices {
            display: flex;
            justify-content: center;
            align-items: center;

            .money {
              font-size: 1.55rem;
            }

            .unit {
              font-size: .8rem;
            }
          }
        }
      }
    }

    .el-button {
      width: 100%;
    }
  }
}


.content {
  padding: 15px 15px 45px;
  box-sizing: border-box;
  background: #ffffff;
  //margin-bottom: 45px;

  .content-toolbar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;

    .toolbar-its {
      //width: 120px;
      padding-bottom: 10px;
      position: relative;

      & + .toolbar-its {
        margin-left: 25px;
      }

      &:after {
        content: "";
        position: absolute;
        left: 23%;
        right: 23%;
        height: 5px;
        border-radius: 8px;
        background: #ffffff;
        top: 100%;
      }

      &.active-toolbar {
        color: #C7000A;

        &:after {
          background: linear-gradient(90deg,
          #F28F00 0%,
          #FCC900 100%);
        }
      }
    }
  }

  /deep/ .recharge-its {
    //margin: 15px;
    text-align: justify;
    padding-left: 10px;
    line-height: 1.75;
    font-size: .85rem;

    .el-text {
      font-size: .85rem;
    }
  }

  /deep/ .step {
    //margin: 0;
    //position:;
    .tips {
      background: #C7000A;
      margin-left: -15px;
      padding: 5px 15px;
      color: #ffffff;
      font-weight: 600;
      width: 76px;
      border-radius: 0 45px 45px 0;
      margin-bottom: 15px;
    }
  }
}

.banner {
  --el-card-border-radius: 0;
  --el-card-padding: 0;
  //margin: 15px;
  height: 160px;
}
</style>
