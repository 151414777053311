<script setup>
const wxSer = ()=>{
  window.open('https://work.weixin.qq.com/kfid/kfcdbea4cd310cec514')
}
</script>

<template>
  <div class="customer-component" @click="wxSer">
    <el-icon size="22"><Service /></el-icon>
  </div>
</template>

<style scoped lang="less">
.customer-component{
  text-align: center;
  position: fixed;
  right: 10px;
  bottom: 80px;
  background: rgba(242, 143, 0, 1);
  padding: 5px;
  border-radius: 50%;
  color: #ffffff;
  width: 25px;
  height: 25px;
  line-height: 25px;
}
</style>
