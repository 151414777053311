<script setup>
import {useRoute} from "vue-router";

const route = useRoute()
</script>
<template>
  <div class="content-scrollbar" v-if="route.name !== 'login'">
    <router-view/>
  </div>
  <router-view v-else/>
</template>

<style lang="less">
* {
  padding: 0;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content-scrollbar {
  box-sizing: border-box;
  //padding: 0 15px;
  width: 480px;
  //background: #2C405A;
  //height: 100vh;
  margin: auto;
  //background: var(--el-color-info-light-8);
}

.page-content {
  height: calc(100vh - 30px) !important;
}

@media screen and (max-width: 800px) {
  .content-scrollbar {
    width: 100vw;
    //padding: 0;
  }
}

.score-number {
  font-size: 1.75rem;
}

.add-card {
  border-radius: 16px 16px 0 0;
}

.coupon-success {
  height: 80px;
  background: url("@/assets/img/coupon-success.png") no-repeat;
  background-size: 100% 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  .coupon-face-value {
    width: 25%;
    flex-basis: 25%;
    // background-color: #0055ff;
    text-align: center;
    display: flex;
    justify-content: center;
    color: #ffffff;

    .coupon-face-value-unit {
      font-size: .75rem;
      margin-top: 3px;
    }

    .coupon-face-value-money {
      font-size: 1.5rem;
    }
  }

  .coupon-jingle {
    width: 75%;
    flex-basis: 75%;
    box-sizing: border-box;
    padding: 10px;
    line-height: 1.4;
    .coupon-jingle-its{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .el-text {
        font-size: .6rem;
      }
    }

  }

  .coupon-tips {
    position: absolute;
    top: 0;
    right: -30px;
    width: 65px;
    text-align: center;
    background: #ffffff;
    //line-height: 25px;
    //旋转45度
    transform: rotate(45deg);
    box-shadow: 0 0 3px rgba(0, 0, 0, .1);
    padding: 3px 15px 3px 30px;
    .el-text{
      font-size: .75rem;
    }
  }
}
</style>
